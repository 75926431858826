import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { user } = useAuth();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsOpen(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Navigation items for regular users
  const renderNavItems = () => (
    <>
      <Link
        to="/sell-your-home"
        className="group relative px-3 lg:px-4 py-2 text-gray-300 hover:text-white transition duration-200 text-sm lg:text-base font-medium"
        onClick={() => setIsOpen(false)}
      >
        <span>Sell Your Home</span>
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#D4AF37] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </Link>
      <Link
        to="/submit-jv-leads"
        className="group relative px-3 lg:px-4 py-2 text-gray-300 hover:text-white transition duration-200 text-sm lg:text-base font-medium"
        onClick={() => setIsOpen(false)}
      >
        <span>Submit JV Leads</span>
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#D4AF37] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </Link>
      <Link
        to="/how-it-works"
        className="group relative px-3 lg:px-4 py-2 text-gray-300 hover:text-white transition duration-200 text-sm lg:text-base font-medium"
        onClick={() => setIsOpen(false)}
      >
        <span>How It Works</span>
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#D4AF37] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </Link>
      <Link
        to="/contact"
        className="group relative px-3 lg:px-4 py-2 text-gray-300 hover:text-white transition duration-200 text-sm lg:text-base font-medium"
        onClick={() => setIsOpen(false)}
      >
        <span>Contact</span>
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#D4AF37] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </Link>
      {user?.isAdmin && (
        <Link
          to="/admin-dashboard"
          className="text-[#D4AF37] font-medium hover:text-white transition duration-200 text-sm lg:text-base"
          onClick={() => setIsOpen(false)}
        >
          Admin
        </Link>
      )}
    </>
  );

  // Navigation items for admin users
  const renderAdminNavItems = () => (
    <>
      <Link
        to="/admin-dashboard"
        className="group relative px-4 py-2 text-gray-300 hover:text-white transition duration-200 text-sm lg:text-base font-medium"
        onClick={() => setIsOpen(false)}
      >
        <span>Dashboard</span>
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#D4AF37] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </Link>
      {/* Add more admin links here as needed */}
    </>
  );

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 
      ${
        scrolled
          ? "bg-gradient-to-b from-black via-[#111111] to-black bg-opacity-98"
          : "bg-gradient-to-b from-black via-[#111111] to-black bg-opacity-90"
      }`}
    >
      {/* Texture overlay with pointer-events-none */}
      <div
        className="absolute inset-0 opacity-5 pointer-events-none"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2V6h4V4H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link
            to="/"
            className="flex-shrink-0"
            onClick={() => setIsOpen(false)}
          >
            <img
              src="/images/logo-11.png"
              alt="Next Home Capital"
              className="h-10 sm:h-12 lg:h-14 w-auto transition-all duration-300"
            />
          </Link>

          {/* Desktop/Tablet Navigation */}
          <div className="hidden sm:flex items-center space-x-2 lg:space-x-6">
            {isAdminRoute ? renderAdminNavItems() : renderNavItems()}

            {user ? (
              <button
                onClick={handleLogout}
                className="group flex items-center space-x-2 bg-black/50 text-[#D4AF37] px-3 lg:px-4 py-1.5 lg:py-2 rounded-lg hover:bg-black/70 border border-[#D4AF37]/20 transition duration-300 text-sm lg:text-base ml-2"
              >
                <span>Logout</span>
                <ArrowRightOnRectangleIcon className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
            ) : (
              <Link
                to="/login"
                className="bg-[#D4AF37] text-white px-3 lg:px-4 py-1.5 lg:py-2 rounded-lg hover:bg-opacity-90 transition duration-300 transform hover:scale-105 text-sm lg:text-base ml-2"
              >
                Login
              </Link>
            )}
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="sm:hidden p-2 rounded-lg hover:bg-gray-800 transition duration-200"
            aria-label="Toggle menu"
          >
            <svg
              className={`h-6 w-6 text-white transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Updated Mobile Navigation with texture */}
        <div
          className={`sm:hidden transition-all duration-300 ease-in-out ${
            isOpen
              ? "max-h-screen opacity-100"
              : "max-h-0 opacity-0 pointer-events-none"
          }`}
        >
          <div className="py-4 space-y-3 bg-black bg-gradient-to-b from-black to-[#111111] shadow-lg rounded-b-lg">
            {/* Mobile Nav Links with improved spacing and layout */}
            <div className="flex flex-col space-y-2 px-4">
              <Link
                to="/sell-your-home"
                className="py-2 text-gray-300 hover:text-white transition duration-200 text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Sell Your Home
              </Link>
              <Link
                to="/submit-jv-leads"
                className="py-2 text-gray-300 hover:text-white transition duration-200 text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Submit JV Leads
              </Link>
              <Link
                to="/how-it-works"
                className="py-2 text-gray-300 hover:text-white transition duration-200 text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                How It Works
              </Link>
              <Link
                to="/contact"
                className="py-2 text-gray-300 hover:text-white transition duration-200 text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </Link>
              {user?.isAdmin && (
                <Link
                  to="/admin-dashboard"
                  className="py-2 text-[#D4AF37] hover:text-white transition duration-200 text-base font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  Admin
                </Link>
              )}
            </div>

            {/* Mobile Auth Button with improved spacing */}
            <div className="mt-4 pt-4 border-t border-gray-800 px-4">
              {user ? (
                <button
                  onClick={handleLogout}
                  className="w-full flex items-center justify-between py-3 px-4 text-[#D4AF37] hover:bg-black/50 rounded-lg transition duration-200 font-medium"
                >
                  <span>Logout</span>
                  <ArrowRightOnRectangleIcon className="h-5 w-5" />
                </button>
              ) : (
                <Link
                  to="/login"
                  className="block w-full py-3 px-4 bg-[#D4AF37] text-white text-center rounded-lg hover:bg-opacity-90 transition duration-200 font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
