import React, { useRef } from 'react';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';

// Use the same color palette as HomePage
const colors = {
  primary: "#2C3E50",
  secondary: "#34495E",
  accent: "#D4AF37",
  light: "#ECF0F1",
  white: "#FFFFFF",
  text: "#333333",
};

const patterns = {
  dots: "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E\")",
  grid: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\")",
};

function JVLeads() {
  const formRef = useRef(null);
  const formVisible = useIntersectionObserver(formRef, { threshold: 0.1 });

  return (
    <div className="font-sans text-text">
      {/* Hero Section */}
      <section className="relative h-[60vh] overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('/images/luxury.webp')",
            opacity: 0.8,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-transparent opacity-70" />
        <div className="absolute inset-0 flex items-center z-10">
          <div className="container mx-auto px-6">
            <div className="max-w-3xl text-white">
              <h1 className="text-5xl font-bold mb-6">
                Submit Your JV Leads – Let's Close Deals Together!
              </h1>
              <p className="text-xl mb-4">
                Got a Motivated Seller? Send Us Your Leads & Let's Get to the Closing Table!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content Section */}
      <section 
        className="py-16"
        style={{
          backgroundColor: colors.light,
          backgroundImage: patterns.dots,
        }}
      >
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-xl p-8 mb-12">
            <p className="text-xl mb-8">
              We specialize in locking up deals as Novations and selling them on the MLS for maximum profit.
            </p>

            <div className="mb-12">
              <h3 className="text-2xl font-bold text-primary mb-6">How It Works:</h3>
              <ol className="list-decimal pl-6 space-y-4 text-lg">
                <li className="text-gray-700">Submit your hottest seller leads using the form below.</li>
                <li className="text-gray-700">Our team handles the heavy lifting—locking up contracts, managing negotiations, and getting the deal across the finish line.</li>
                <li className="text-gray-700">We determine our Max Offer based on 90% of the property's Current Condition Value (CCV)—the price it would sell for on the MLS within 30 days with little to no work—minus $30K for our profit.</li>
              </ol>
            </div>

            <div className="mb-12 bg-gray-50 p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-primary mb-6">What to Tell the Seller:</h3>
              <ul className="space-y-4">
                <li className="flex items-center space-x-3 text-red-600">
                  <span className="text-2xl">🛑</span>
                  <span>DO NOT mention novations or partners.</span>
                </li>
                <li className="flex items-center space-x-3 text-green-600">
                  <span className="text-2xl">✅</span>
                  <span>Tell them you're waiting on a final decision from your team before making an offer and will follow up in a few days.</span>
                </li>
              </ul>
            </div>

            <div className="mb-12 bg-gray-50 p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-primary mb-6">How Much Do You Make?</h3>
              <ul className="space-y-4 text-lg">
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">💰</span>
                  <span>JV Split: 40% (you) / 60% (us)</span>
                </li>
                <li className="flex items-start space-x-3">
                  <span className="text-2xl">💰</span>
                  <div>
                    <p>If any minor repairs, moving assistance, or lender-required fixes are needed, we can:</p>
                    <ul className="pl-6 mt-2 space-y-2">
                      <li>• Split the cost 50/50</li>
                      <li>• Or, whoever covers the expense will get reimbursed + a return for taking on the risk.</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Form Section */}
          <div 
            ref={formRef}
            className={`max-w-3xl mx-auto transition-all duration-1000 transform ${
              formVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            <div className="bg-white p-8 rounded-xl shadow-xl text-center">
              <h3 className="text-3xl font-bold text-primary mb-8">Submit Your Lead</h3>
              <a
                href="https://podio.com/webforms/30205496/2531967"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-[#D4AF37] text-white px-12 py-4 rounded-full text-lg font-semibold transition-all duration-300 transform hover:bg-opacity-90 hover:scale-105"
              >
                🚀 Submit JV Lead 🚀
              </a>
            </div>

            <div className="text-center mt-8 space-y-2 text-gray-600">
              <p>Our team will reach out within 2 business days to guide you through the next steps.</p>
              <p className="font-semibold">We won't contact the seller first—everything goes through you!</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JVLeads;