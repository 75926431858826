import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "./firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import {
  UsersIcon,
  HomeIcon,
  ChartBarIcon,
  CogIcon,
  DocumentTextIcon,
  PhoneIcon,
  HandRaisedIcon,
} from "@heroicons/react/24/outline";
import TimeIcon from "./components/TimeIcon";

function AdminDashboard() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [adminName, setAdminName] = useState("");
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalRequests: 0,
    newRequests: 0,
    totalContacts: 0,
    newContacts: 0,
  });

  useEffect(() => {
    // Timer for clock
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Get admin data from Firestore
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setAdminName(
              userData.name || userData.email?.split("@")[0] || "Admin"
            );
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setAdminName(user.email?.split("@")[0] || "Admin");
        } finally {
          setLoading(false);
        }
      }
    });

    // Add this function to fetch stats
    const fetchStats = async () => {
      try {
        // Get current date minus 7 days for "new" items
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        console.log("Last week date:", lastWeek);

        // Fetch sell requests
        const requestsRef = collection(db, "homeSellerRequests");
        const requestsSnapshot = await getDocs(requestsRef);

        // Log all requests with their dates for debugging
        const allRequests = requestsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: new Date(doc.data().createdAt),
        }));
        console.log("All requests:", allRequests);

        const totalRequests = requestsSnapshot.size;

        // Count new requests (last 7 days)
        const newRequests = allRequests.filter((request) => {
          const createdDate = new Date(request.createdAt);
          return createdDate >= lastWeek;
        }).length;

        // Fetch contacts
        const contactsRef = collection(db, "contact_inquiries");
        const contactsQuery = query(contactsRef, orderBy("timestamp", "desc"));
        const contactsSnapshot = await getDocs(contactsQuery);

        const allContacts = contactsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
        }));
        console.log("All contacts:", allContacts);

        const totalContacts = contactsSnapshot.size;

        // Count new contacts (last 7 days)
        const newContacts = allContacts.filter((contact) => {
          const contactDate = contact.timestamp;
          return contactDate && contactDate >= lastWeek;
        }).length;

        // Log the final stats
        // Fetch JV leads
        const jvLeadsRef = collection(db, "jv_leads");
        const jvLeadsQuery = query(jvLeadsRef, orderBy("timestamp", "desc"));
        const jvLeadsSnapshot = await getDocs(jvLeadsQuery);

        const allJVLeads = jvLeadsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
        }));

        const totalJVLeads = jvLeadsSnapshot.size;

        // Count new JV leads (last 7 days)
        const newJVLeads = allJVLeads.filter((lead) => {
          const leadDate = lead.timestamp;
          return leadDate && leadDate >= lastWeek;
        }).length;

        const newStats = {
          totalRequests,
          newRequests,
          totalContacts,
          newContacts,
          totalJVLeads,
          newJVLeads,
        };

        console.log("Setting stats:", newStats);
        setStats(newStats);
      } catch (error) {
        console.error("Error fetching stats:", error);
        console.error("Error details:", {
          message: error.message,
          code: error.code,
          stack: error.stack,
        });
      }
    };

    fetchStats();

    return () => {
      clearInterval(timer);
      unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading dashboard...</p>
          </div>
        </div>
      </div>
    );
  }

  const getGreeting = () => {
    const hour = currentTime.getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  const statsCards = [
    {
      title: "Total Sell Requests",
      value: stats.totalRequests,
      newItems: stats.newRequests,
      icon: HomeIcon,
      color: "purple",
      path: "/admin/sell-requests",
    },
    {
      title: "Contact Inquiries",
      value: stats.totalContacts,
      newItems: stats.newContacts,
      icon: PhoneIcon,
      color: "orange",
      path: "/admin/contact-inquiries",
    },
    {
      title: "JV Leads",
      value: stats.totalJVLeads || 0,
      newItems: stats.newJVLeads || 0,
      icon: HandRaisedIcon,
      color: "indigo",
      path: "/admin/jv-leads",
    },
  ];

  const adminCards = [
    {
      title: "JV Leads",
      description: "Manage Joint Venture lead submissions",
      icon: HandRaisedIcon,
      path: "/admin/jv-leads",
      color: "indigo",
    },
    {
      title: "Sell Requests",
      description: "View and manage property sell requests",
      icon: HomeIcon,
      path: "/admin/sell-requests",
      color: "purple",
    },
    {
      title: "User Management",
      description: "Manage user accounts and permissions",
      icon: UsersIcon,
      path: "/admin/manage-users",
      color: "blue",
    },
    {
      title: "Analytics",
      description: "View website traffic and conversion metrics",
      icon: ChartBarIcon,
      path: "/admin/analytics",
      color: "green",
    },
    {
      title: "Contact Inquiries",
      description: "Manage customer inquiries and messages",
      icon: PhoneIcon,
      path: "/admin/contact-inquiries",
      color: "orange",
    },
    {
      title: "Content Management",
      description: "Edit website content and blog posts",
      icon: DocumentTextIcon,
      path: "/admin/content",
      color: "pink",
    },
    {
      title: "Settings",
      description: "Configure system settings and preferences",
      icon: CogIcon,
      path: "/admin/settings",
      color: "gray",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-black via-[#111111] to-black">
        {/* Base noise texture */}
        <div
          className="absolute inset-0 opacity-[0.4]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat",
            mixBlendMode: "soft-light",
          }}
        />

        {/* Diagonal grid */}
        <div
          className="absolute inset-0 opacity-[0.15]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 60L60 0H30L0 30M60 60V30L30 60' stroke='%23D4AF37' stroke-width='0.8' fill='none' /%3E%3C/svg%3E")`,
            backgroundSize: "60px 60px",
          }}
        />

        {/* Gold dust effect */}
        <div
          className="absolute inset-0 opacity-[0.08]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23D4AF37' fill-opacity='0.5'%3E%3Ccircle cx='40' cy='40' r='0.8'/%3E%3Ccircle cx='20' cy='60' r='0.6'/%3E%3Ccircle cx='60' cy='20' r='0.7'/%3E%3Ccircle cx='10' cy='10' r='0.5'/%3E%3Ccircle cx='70' cy='70' r='0.5'/%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: "80px 80px",
          }}
        />

        {/* Depth gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-black/60 via-transparent to-black/60"
          style={{
            mixBlendMode: "overlay",
          }}
        />

        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <h1 className="text-4xl font-bold sm:text-5xl">
              <span className="text-white">Admin Dashboard</span>
            </h1>
            <p className="mt-4 text-xl text-gray-300">
              Manage your website and customer requests
            </p>
          </div>
        </div>
      </div>

      {/* Greeting Card - adjusted positioning */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="transform -translate-y-8">
          <div className="bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg p-6 border border-gray-100">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {getGreeting()}, {adminName}
                </h2>
                <p className="text-gray-600 mt-1">
                  {currentTime.toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <p className="text-gray-500">
                  {currentTime.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
              <TimeIcon size={48} isDarkMode={false} />
            </div>
          </div>
        </div>
      </div>

      {/* Stats Cards */}
      <div className="max-w-7xl mx-auto px-4 mb-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {statsCards.map((card) => (
            <Link key={card.title} to={card.path}>
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition duration-300">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-lg text-gray-600">{card.title}</p>
                    <div className="flex items-baseline">
                      <p className="text-3xl font-bold text-gray-900">
                        {card.value}
                      </p>
                      {card.newItems > 0 && (
                        <span className="ml-2 px-2.5 py-0.5 bg-green-100 text-green-800 text-sm font-medium rounded-full">
                          +{card.newItems} new
                        </span>
                      )}
                    </div>
                    <p className="mt-1 text-sm text-gray-500">
                      {card.newItems} new in last 7 days
                    </p>
                  </div>
                  <div className={`p-4 bg-${card.color}-100 rounded-full`}>
                    <card.icon className={`w-8 h-8 text-${card.color}-600`} />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="relative w-full h-3 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className={`absolute top-0 left-0 h-full bg-${card.color}-500`}
                      style={{
                        width: `${(card.newItems / card.value) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Admin Cards Grid */}
      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {adminCards.map((card) => (
            <Link key={card.title} to={card.path} className="group">
              <div className="h-full bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition duration-300">
                <div className="flex items-center space-x-4 mb-4">
                  <div className={`p-3 bg-${card.color}-100 rounded-lg`}>
                    <card.icon className={`w-6 h-6 text-${card.color}-600`} />
                  </div>
                  <h2 className="text-xl font-semibold text-gray-900">
                    {card.title}
                  </h2>
                </div>
                <p className="text-gray-600">{card.description}</p>
                <div
                  className={`mt-4 flex items-center text-${card.color}-600 group-hover:text-${card.color}-700`}
                >
                  <span>Manage</span>
                  <svg
                    className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
